<template>
  <div class="air">
    <air-header />
    <div id='fullscreen'>
    </div>
    <div class="left">
      <left-top />
      <left-bottom />
    </div>
    <center-top />
    <center-bottom />
    <div class="right">
      <right-top />
      <right-center />
      <right-bottom />
    </div>
  </div>
</template>

<script>
import { getAllLocation, getUser } from '@/api'
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import AirHeader from './components/header'
import LeftTop from './components/leftTop'
import LeftBottom from './components/leftBottom'
import CenterTop from './components/centerTop'
import CenterBottom from './components/centerBottom'
import RightTop from './components/rightTop'
import RightCenter from './components/rightCenter'
import RightBottom from './components/rightBottom'
export default {
  components: { AirHeader, LeftTop, LeftBottom, CenterTop, CenterBottom, RightTop, RightCenter, RightBottom },
  data () {
    return {
      map: null,
      markList: [],
      cityName: ''
    };
  },
  created () {

  },
  mounted () {
    this.init()
    window.onload = function () {
      let h = document.getElementsByClassName("left")[0].offsetHeight
      const height = h + 67
      document.getElementsByClassName("air")[0].style.height = height + 'px';
    }
    // this.loadMap()
  },
  methods: {
    init () {
      getUser().then(res => {
        const { unitTree } = res.data
        this.cityName = unitTree[0].children[unitTree[0].children.length - 1].children[0].name
        getAllLocation().then(res => {
          this.markList = res.data.filter(el => el.unit !== '钛比科技')
          this.loadMap()
        })
      })
    },
    loadMap () {
      this.map = new AMap.Map('fullscreen', {
        resizeEnable: true,
        viewMode: '3D',
        mapStyle: 'amap://styles/darkblue',
        pitch: 40,
        // // center: [108.909759, 34.412745],
        // // center: [121.045332, 31.19884],
        // zoom: 8.8
      })
      const markerList = []
      this.markList.forEach(el => {
        el.temp = new AMap.Marker({
          map: this.map,
          // icon: el.icon,
          position: [el.longitude, el.latitude],
          title: el.unit,
        })
        markerList.push(el.temp)
      })
      // this.Action(jsonmap)
      this.map.add(markerList)
      this.loadMapData()
    },
    loadMapData () {
      const _this = this
      AMap.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          // city: '010'
        })
        geocoder.getLocation(_this.cityName, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result中对应详细地理坐标信息
            AMapUI.loadUI(['geo/DistrictExplorer'], function (DistrictExplorer) {

              //创建一个实例
              var districtExplorer = new DistrictExplorer({
                map: _this.map
              })
              var adcode = result.geocodes[0].adcode
              // var colors = [
              //   "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C",
              //   "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C", "#DC143C",
              //   "#DC143C", "#DC143C", "#DC143C", "#DC143C"
              // ]
              districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
                //更新地图视野
                _this.map.setBounds(areaNode.getBounds(), null, null, true);

                //清除已有的绘制内容
                districtExplorer.clearFeaturePolygons();

                //绘制子区域
                districtExplorer.renderSubFeatures(areaNode, function () {
                  // var fillColor = colors[i % colors.length];
                  // var strokeColor = colors[colors.length - 1 - i % colors.length];
                  return {
                    cursor: 'default',
                    bubble: true,
                    strokeColor: '#12558A', //线颜色
                    strokeOpacity: 1, //线透明度
                    strokeWeight: 1, //线宽
                    fillColor: '#12558A', //填充色
                    fillOpacity: 0.35, //填充透明度
                  }
                })

                //绘制父区域
                districtExplorer.renderParentFeature(areaNode, {
                  cursor: 'default',
                  bubble: true,
                  strokeColor: '#12558A', //线颜色
                  strokeOpacity: 0.6, //线透明度
                  strokeStyle: 'dashed',
                  strokeWeight: 3, //线宽
                  fillColor: "#12558A", //填充色
                  fillOpacity: 0.35, //填充透明度
                })
              })
            })
          }
        })
      })

    }
  }
};
</script>

<style scoped lang="scss">
.air {
  background: #000001;
  padding-top: 1px;
  position: relative;
  #fullscreen {
    width: 100vw;
    height: calc(100vh - 85px);
  }
  .left {
    width: 514px;
    position: absolute;
    z-index: 18;
    top: 66px;
    // bottom: 0;
    left: 34px;
  }
  .right {
    width: 514px;
    position: absolute;
    z-index: 18;
    top: 66px;
    right: 36px;
  }
  // .center {
  //   // width: 789px;
  //   margin: 41px 10px 0 24px;
  //   height: 100px;
  //   // position: absolute;
  //   // top: 108px;
  // }
}
</style>
