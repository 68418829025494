<template>
  <div class='airheader'>
    <p>{{cityName}}影院空气监测管理平台</p>
  </div>
</template>

<script>
import { getUser } from '@/api'

export default {
  data () {
    return {
      cityName: ''
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getUser().then(res => {
        const { unitTree } = res.data
        this.cityName = unitTree[0].children[unitTree[0].children.length - 1].children[0].name.split('区')[0]
      })
    }
  }
};
</script>

<style scoped lang="scss">
.airheader {
  margin-top: 10px;
  height: 74px;
  width: 100%;
  background: url("../../../../assets/air/header.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  p {
    width: 529px;
    margin: 8px auto 0;
    font-size: 42px;
    font-weight: 400;
    color: #e8effb;
    font-weight: 600;
    letter-spacing: 2px;
    background: linear-gradient(0deg, #12d1ff 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
