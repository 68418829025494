<template>
  <div class='air-right-top aircommon'>
    <div class="title">
      实时监测数据
      <p>
        <span>{{location}}</span>
      </p>
    </div>
    <div class="swiper swiper_singleData " v-if="timeDataList.length>0">
      <div class="swiper-wrapper">
        <div v-for="(el, index) in timeDataList" class="swiper-slide" :key="index">
          <div :id="`radarecharts${index}`" class="radarecharts"></div>
          <div class="radarmsg">
            <p>甲醛<span>{{el.formaldehyde}}</span><i>mg/m3</i></p>
            <p>温度<span>{{el.temperature}}</span><i>℃</i></p>
            <p>湿度<span>{{el.humidity}}</span><i>%</i></p>
            <p>CO<span>{{el.co}}</span><i>mg/m3</i></p>
            <p>CO2<span>{{el.co2}}</span><i>ppm</i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRealTimeData } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      // chart: null,
      timeDataList: [],
      location: '',
      index: 0,
      timer: null
    };
  },
  created () {

  },
  mounted () {
    this.init()

  },
  methods: {
    init () {
      getRealTimeData().then(res => {
        this.timeDataList = res.data
        this.location = this.timeDataList[0].location
        if (this.timeDataList.length > 1) {
          this.timer = setInterval(() => {
            if (this.index === this.timeDataList.length - 1) {
              this.index = 0
            } else {
              this.index++
            }
            this.location = this.timeDataList[this.index].location
          }, 10000)
        }
        this.$nextTick(() => {
          // this.drawEcharts()
          this.initSwiper()
          this.timeDataList.map((el, index) => {
            this.drawEcharts(`radarecharts${index}`, index)
          })
        })
      })
    },
    drawEcharts (id, index) {
      let chart = this.$echarts.init(document.getElementById(id))
      const singleMsg = this.timeDataList[index]
      const option = {
        radar: {
          center: ['50%', '55%'],
          indicator: [
            { name: '甲醛', max: singleMsg['maxFormaldehyde'] },
            { name: '湿度', max: singleMsg['maxHumidity'] },
            { name: '温度', max: singleMsg['maxTemperature'] },
            { name: 'CO2', max: singleMsg['maxCo2'] },
            { name: 'CO', max: singleMsg['maxCo'] }
          ],
          axisName: {
            color: "rgba(202, 202, 202, 1)",
            fontSize: 14,
            fontWeight: 600
          },
          // 边框样式
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: 'rgba(0, 255, 255, 0.2)',
              width: 2
            }
          },
          // 对角线样式
          axisLine: {
            lineStyle: {
              color: "rgba(0, 255, 255, 0.2)",
              type: "dashed",
              width: 2
            }
          },
          // 区域样式
          splitArea: {
            areaStyle: {
              color: ['rgba(250,250,250,0)', 'rgba(200,200,200,0)']
            }
          }
        },
        series: [
          {
            // name: 'Budget vs spending',
            type: 'radar',
            // 
            symbolSize: 6,
            itemStyle: {
              color: "rgba(253, 179, 87, 1)"
            },
            lineStyle: {
              color: "rgba(253, 179, 87, 1)",
              width: 0.5
            },
            areaStyle: {
              color: "rgba(31, 34, 24, 0.8)"
            },
            data: [
              {
                value: [singleMsg.formaldehyde, singleMsg.humidity, singleMsg.temperature, singleMsg.co2, singleMsg.co]
                // name: 'Allocated Budget'
              }
            ]
          }
        ]
      }
      option && chart.setOption(option)
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.timeDataList && this.timeDataList.length > 1) {
          new Swiper('.swiper_singleData', {
            autoplay: {
              delay: 10000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            // direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            // speed: 3000
          })
        }
        if (this.timeDataList && this.timeDataList.length <= 1) {
          new Swiper('.swiper_singleData', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 3000
          })
        }
      })
    }
  },
  destroyed () {
    clearInterval(this.timer)
  }
};
</script>

<style scoped lang="scss">
.air-right-top {
  width: 100%;
  height: 300px;
  background: url("../../../../assets/air/right_top.png") no-repeat;
  background-size: 100% 100%;
  .title {
    display: flex;
    align-items: center;
    p {
      margin-left: 70px;
      height: 30px;
      line-height: 30px;
      span {
        background: rgba(0, 0, 0, 0.4);
        display: inline-block;
        border-radius: 2px;
        font-size: 16px;
        color: #15eaff;
        padding: 0 20px;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
  .radar {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    width: 100%;
    padding: 0 40px 0 30px;
    height: 220px;
    margin-top: 12px;
    box-sizing: border-box;
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .radarecharts {
      width: 60%;
      height: 100%;
    }
    .radarmsg {
      p {
        font-size: 16px;
        color: #cacaca;
        margin-bottom: 15px;
        span {
          font-size: 24px;
          font-weight: 800;
          color: #32daff;
          margin: 0 5px 0 16px;
        }
        i {
          color: #32daff;
        }
      }
    }
  }
}
</style>
