<template>
  <div class='air-left-bottom aircommon'>
    <div class="title">
      实时数据排行
      <p>
        <span v-for="(item,index) in tabList" :key="item.id" :class="num === index?'active':''" @click="changeTab(index)">{{item.name}}</span>
      </p>
    </div>
    <div class="realTimeData_content">
      <el-row class="realTimeData_title">
        <el-col :span="2"></el-col>
        <el-col :span="6">单位</el-col>
        <el-col :span="9">影厅</el-col>
        <el-col :span="4">当前数值</el-col>
        <el-col :span="3">报警值</el-col>
      </el-row>
      <div class="swiper swiper_realTimeData" v-if="realTimeDataList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in realTimeDataList" class="swiper-slide" :key="index">
            <el-col :span="2">
              <span :class="`type ${index === 0?'type1':index === 1?'type2':index===2?'type3':''}`">{{index+1}}</span>
            </el-col>
            <el-col :span="6">{{el.unit}}</el-col>
            <el-col :span="9">{{el.location}}</el-col>
            <el-col :span="4">{{el.value}}</el-col>
            <el-col :span="3">{{el.threshold}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRealTimeRank } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      tabList: [
        { id: 1, name: '甲醛', type: 'formaldehyde' },
        { id: 2, name: 'CO', type: 'co' },
        { id: 3, name: 'CO2', type: 'co2' }
      ],
      num: 0,
      realTimeDataList: [],
      realTimeDataSwiper: null
    }
  },
  created () {

  },
  mounted () {
    this.init('formaldehyde')
  },
  methods: {
    init (type) {
      if (this.realTimeDataSwiper) {
        this.realTimeDataSwiper.destroy()
      }
      this.realTimeDataList = []
      getRealTimeRank(type).then(res => {
        this.realTimeDataList = res.data
        this.initSwiper()
      })
    },
    changeTab (index) {
      this.num = index
      this.init(this.tabList[index].type)
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.realTimeDataList && this.realTimeDataList.length > 12) {
          this.realTimeDataSwiper = new Swiper('.swiper_realTimeData', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.realTimeDataList && this.realTimeDataList.length <= 12) {
          this.realTimeDataSwiper = new Swiper('.swiper_realTimeData', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.air-left-bottom {
  margin-top: 32px;
  width: 100%;
  height: 557px;
  background: url("../../../../assets/air/left_bottom.png") no-repeat;
  background-size: 100% 100%;
  .title {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    p {
      display: flex;
      margin-left: 36px;
      height: 30px;
      line-height: 30px;
      // padding: 2px 0;
      span {
        display: inline-block;
        border: 1px solid rgba(202, 202, 202, 0.5);
        border-radius: 2px;
        font-size: 16px;
        color: #cacaca;
        padding: 0 20px;
        cursor: pointer;
      }
      .active {
        font-weight: 500;
        color: #15eaff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        border: none;
      }
    }
  }
  .realTimeData_content {
    padding: 24px 27px 0 25px;
    background: rgba(4, 32, 49, 0.4);
    .realTimeData_title {
      font-size: 16px;
      font-weight: bold;
      color: #15eaff;
      display: flex;
      .el-col {
        float: none;
      }
    }
    .swiper_realTimeData {
      overflow: hidden;
      height: 440px;
      margin-top: 5px;
      .swiper-slide {
        display: flex;
        margin-top: 16px;
        height: 20px;
        .el-col {
          float: none;
          font-size: 14px;
          color: #cacaca;
          white-space: nowrap;
          // height: 100%;
        }
        .el-col-4 {
          color: #15eaff;
        }
        .type {
          display: block;
          width: 20px;
          height: 20px;
          background: #93abb9;
          border-radius: 50%;
          color: #ffffff;
          text-align: center;
          line-height: 20px;
        }
        .type1 {
          background: #fd5757;
        }
        .type2 {
          background: #ff7038;
        }
        .type3 {
          background: #ffc038;
        }
      }
    }
  }
}
</style>
