<template>
  <div class='air_right_bottom aircommon'>
    <p class="title">
      本月影院报警排行
    </p>
    <div class="rankData_content">
      <div class="swiper swiper_rankData" v-if="rankDataList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in rankDataList" class="swiper-slide" :key="index">
            <el-col :span="6" :style="{color:index === 0?'#fd5757':index === 1?'#ff7038':index===2?'#ffc038':'#cacaca'}">{{`No.${index+1}`}}</el-col>
            <el-col :span="12">{{el.name}}</el-col>
            <el-col :span="6" class="number">{{el.value}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMonthRank } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      rankDataList: [
        // { id: 1, name: '大西洋银泰百货' },
        // { id: 2, name: '大西洋银泰百货' },
        // { id: 3, name: '大西洋银泰百货' },
        // { id: 4, name: '大西洋银泰百货' }
      ]
    };
  },
  created () {

  },
  mounted () {
    this.init()

  },
  methods: {
    init () {
      getMonthRank().then(res => {
        const { data } = res
        const keyList = Object.keys(data)
        if (keyList.length > 0) {
          keyList.map(el => {
            this.rankDataList.push({
              name: el,
              value: data[el]
            })
          })
          this.initSwiper()
        }
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.rankDataList && this.rankDataList.length > 4) {
          new Swiper('.swiper_rankData', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.rankDataList && this.rankDataList.length <= 4) {
          new Swiper('.swiper_rankData', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.air_right_bottom {
  width: 100%;
  height: 319px;
  background: url("../../../../assets/air/right_bottom.png") no-repeat;
  background-size: 100% 100%;
  .rankData_content {
    padding: 30px 60px 0 67px;
    background: rgba(4, 32, 49, 0.4);
    .swiper_rankData {
      overflow: hidden;
      height: 200px;
      .swiper-slide {
        display: flex;
        margin-bottom: 30px;
        height: 20px;
        .el-col {
          float: none;
          font-size: 14px;
          color: #cacaca;
          text-align: center;
          font-weight: bold;
        }
        .number {
          color: #15eaff;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
