<template>
  <div class='air_center_top'>
    <ul>
      <li>
        <p>
          <img src="@/assets/air/cinema.png" alt="">
        </p>
        <div>
          <h3>{{statistics.unitCount}}</h3>
          <h4>影院</h4>
        </div>
      </li>
      <li>
        <p>
          <img src="@/assets/air/location.png" alt="">
        </p>
        <div>
          <h3>{{statistics.locationCount}}</h3>
          <h4>影厅</h4>
        </div>
      </li>
      <li>
        <p>
          <img src="@/assets/air/equipment.png" alt="">
        </p>
        <div>
          <h3>{{statistics.deviceCount}}</h3>
          <h4>设备</h4>
        </div>
      </li>
      <li>
        <p>
          <img src="@/assets/air/day.png" alt="">
        </p>
        <div>
          <h3>{{statistics.day}}</h3>
          <h4>运行天数</h4>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getAll } from '@/api'
export default {
  data () {
    return {
      statistics: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getAll().then(res => {
        this.statistics = res.data
      })
    }
  }
};
</script>

<style scoped lang="scss">
.air_center_top {
  position: absolute;
  top: 107px;
  left: 50%;
  margin-left: -394px;
  ul {
    display: flex;
    li {
      display: flex;
      align-items: center;
      p {
        width: 92px;
        height: 92px;
        background: url("../../../../assets/air/icon_bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > div {
        width: 91px;
        height: 79px;
        background: url("../../../../assets/air/number_bg.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        margin-left: -13px;
        h3 {
          font-size: 32px;
          font-weight: 500;
          color: #15eaff;
          margin: 6px 0 10px;
        }
        h4 {
          font-size: 22px;
          font-weight: 400;
          color: #ffffff;
          white-space: nowrap;
        }
      }
      margin-left: 26px;
    }
    li:first-child {
      margin-left: 0;
    }
  }
}
</style>
