<template>
  <div class='air_right_center aircommon'>
    <div class="title">
      近7天监测数据
      <p>
        <span v-for="(item,index) in tabList" :key="item.id" :class="num === index?'active':''" @click="changeTab(index)">{{item.name}}</span>
      </p>
    </div>
    <div class="bar">
      <div id="barecharts"></div>
    </div>
  </div>
</template>

<script>
import { getWeekData } from '@/api'
export default {
  data () {
    return {
      tabList: [
        { id: 1, name: '甲醛', type: 'formaldehyde' },
        { id: 2, name: 'CO', type: 'co' },
        { id: 3, name: 'CO2', type: 'co2' }
      ],
      num: 0,
      chart: null,
      lineList: [],
      xData: []
    };
  },
  created () {

  },
  mounted () {
    this.init('formaldehyde')
  },
  methods: {
    init (type) {
      if (this.chart) {
        this.chart.dispose()
      }
      this.lineList = []
      this.xData = []
      getWeekData(type).then(res => {
        const { data } = res
        this.xData = Object.keys(data)
        const valueList = Object.values(data)
        // this.
        if (valueList.length > 0) {
          valueList.map(el => {
            const val = JSON.parse(el)
            this.lineList.push({
              max: Number(val[0]),
              min: val[1] === '0.0' ? 0 : Number(val[1]),
              subtract: Number(val[0]) - Number(val[1])
            })
          })
        }

        this.$nextTick(() => {
          this.drawEcharts()
        })
      })
    },
    changeTab (index) {
      this.num = index
      this.init(this.tabList[index].type)
    },
    drawEcharts () {
      this.chart = this.$echarts.init(document.getElementById('barecharts'))
      let yList = []
      let yList1 = []
      let yList2 = []
      if (this.lineList.length > 0) {
        this.lineList.map(el => {
          yList.push(el.min)
          yList1.push(el.subtract)
          yList2.push(el.max)
        })
      }

      let xData = this.xData
      let barWidth = 20
      const _that = this
      const option = {
        /**区域位置*/
        grid: {
          left: '10%',
          right: '5%',
          top: '10%',
          bottom: '18%',
        },
        //X轴
        xAxis: {
          data: xData,
          type: 'category',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255,255,255,1)',
              shadowColor: 'rgba(255,255,255,1)',
              shadowOffsetX: '20',
            },
            // symbol: ['none', 'arrow'],
            // symbolOffset: [0, 25]
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // margin: 30,
            fontSize: 15,
          },
        },
        yAxis: {
          show: true,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#075858'
            },
          },
          axisLabel: {
            color: '#CBFDFB',
            margin: 15,
            fontSize: 15
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: barWidth,
            stack: 'account',
            itemStyle: {
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0,0,0,0)' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(0,0,0,0)' // 100% 处的颜色
                }], false)
              }

            },
            label: {
              show: false,
              position: 'left',
              color: '#CBFDFB',
              fontSize: 14,
              fontStyle: 'bold',
              align: 'center'
            },
            data: yList
          },
          {
            type: 'bar',
            barWidth: barWidth,
            stack: 'account',
            itemStyle: {
              color: function () {
                return new _that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#0937FF' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#4FFEFC' // 100% 处的颜色
                }], false)
              }

            },
            label: {
              show: true,
              position: 'top',
              color: '#CBFDFB',
              fontSize: 14,
              fontStyle: 'bold',
              align: 'center',
              formatter: function (params) {
                const number = `${_that.lineList[params.dataIndex].min} ~ ${_that.lineList[params.dataIndex].max}`
                return number
              }
            },
            data: yList1
          },
          // {
          //   z: 2,
          //   type: 'pictorialBar',
          //   data: yList,
          //   symbol: 'diamond',
          //   symbolOffset: [0, '50%'],
          //   symbolSize: [barWidth, barWidth * 0.5],
          //   itemStyle: {
          //     color: '#4FFEFC'
          //   }
          // },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: yList,
            symbol: 'diamond',
            symbolOffset: [0, '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              color: '#4FFEFC'
            }
          },
          {
            z: 4,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: yList2,
            symbol: 'diamond',
            symbolOffset: [0, '-50%'],
            symbolSize: [barWidth, barWidth * 0.5],
            itemStyle: {
              color: '#F4FE70'
            }
          }
        ]
      }
      option && this.chart.setOption(option)
    }
  }
};
</script>

<style scoped lang="scss">
.air_right_center {
  width: 100%;
  height: 300px;
  background: url("../../../../assets/air/right_top.png") no-repeat;
  background-size: 100% 100%;
  margin: 30px 0 28px;
  .title {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    p {
      display: flex;
      margin-left: 36px;
      height: 30px;
      line-height: 30px;
      // padding: 2px 0;
      span {
        display: inline-block;
        border: 1px solid rgba(202, 202, 202, 0.5);
        border-radius: 2px;
        font-size: 16px;
        color: #cacaca;
        padding: 0 20px;
        cursor: pointer;
      }
      .active {
        font-weight: 500;
        color: #15eaff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        border: none;
      }
    }
  }
  .bar {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    height: 230px;
    margin-top: 10px;
    #barecharts {
      // background: red;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
