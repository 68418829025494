<template>
  <div class='air-center-bottom '>
    <p class="title">
      最新报警数据
    </p>
    <div class="alarmData_content">
      <el-row class="alarmData_title">
        <el-col :span="6">单位名称</el-col>
        <el-col :span="6">影厅</el-col>
        <el-col :span="3">报警类型</el-col>
        <el-col :span="3">报警数据</el-col>
        <el-col :span="6">报警时间</el-col>
      </el-row>
      <div class="swiper swiper_alarmData" v-if="alarmDataList.length>0">
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in alarmDataList" class="swiper-slide" :key="index">
            <el-col :span="6">{{el.unit}}</el-col>
            <el-col :span="6">{{el.location}}</el-col>
            <el-col :span="3">{{el.alarmName}}</el-col>
            <el-col :span="3" style="color:#15EAFF">{{el[el.alarmType]}}</el-col>
            <el-col :span="6">{{el.eventTime}}</el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewAlarm } from '@/api'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      alarmDataList: [
      ]
    };
  },
  created () {

  },
  mounted () {
    this.init()

  },
  methods: {
    init () {
      getNewAlarm().then(res => {
        res.data.list.map(el => {
          switch (el.alarmType) {
            case 'co':
              el.alarmName = 'CO'
              break;
            case 'co2':
              el.alarmName = 'CO2'
              break;
            case 'formaldehyde':
              el.alarmName = '甲醛'
              break;
            case 'temperature':
              el.alarmName = '温度'
              break;
            case 'humidity':
              el.alarmName = '湿度'
              break;
          }
        })
        this.alarmDataList = res.data.list
        this.initSwiper()
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.alarmDataList && this.alarmDataList.length > 7) {
          new Swiper('.swiper_alarmData', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.alarmDataList && this.alarmDataList.length <= 7) {
          new Swiper('.swiper_alarmData', {
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.air-center-bottom {
  width: 789px;
  height: 388px;
  background: url("../../../../assets/air/center.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  // top: 107px;
  bottom: 0;
  left: 50%;
  margin-left: -394px;
  text-align: center;
  .title {
    font-size: 18px;
    color: #ffffff;
    margin-top: 16px;
    // margin: 0 auto;
  }
  .alarmData_content {
    padding: 24px 24px 0 25px;
    background: rgba(4, 32, 49, 0.4);
    .alarmData_title {
      font-size: 16px;
      font-weight: bold;
      color: #15eaff;
      display: flex;
      .el-col {
        float: none;
      }
    }
    .swiper_alarmData {
      overflow: hidden;
      height: 280px;
      margin-top: 20px;
      .swiper-slide {
        display: flex;
        margin-bottom: 25px;
        height: 15px;
        .el-col {
          float: none;
          font-size: 14px;
          color: #ffffff;
        }
        // .el-col-4 {
        //   color: #15eaff;
        // }
      }
    }
  }
}
</style>
