<template>
  <div class='air-left-top aircommon'>
    <p class="title">
      本月累计报警次数
    </p>
    <div class="details">
      <div id="alarm-charts"></div>
      <ul>
        <li v-for="(item,index) in smallCharts" :key="index">
          <p :id="`${item.id}-charts`"></p>
          <div>
            <h4>{{item.value}}</h4>
            <p>{{item.percent}}%</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getMonthAlarm } from '@/api'
export default {
  data () {
    return {
      chart: null,
      timeInterval: null,
      dataIndex: 0,
      chartArr: [
        { value: 0, name: 'CO', itemStyle: { color: '#12B796' } },
        { value: 0, name: 'CO2', itemStyle: { color: '#1DADFF' } },
        { value: 0, name: '甲醛', itemStyle: { color: '#24DCF7' } },
        { value: 0, name: '温度', itemStyle: { color: '#A87EF3' } },
        { value: 0, name: '湿度', itemStyle: { color: '#1C8AC3' } }
      ],
      smallCharts: [
        { id: 'co', name: 'CO', percent: 0, value: 0, color1: '#24DCF7', color2: '#1EF3BE' },
        { id: 'co2', name: 'CO2', percent: 0, value: 0, color1: '#1C8AC3', color2: '#4FB7FF' },
        { id: 'hcho', name: '甲醛', percent: 0, value: 0, color1: '#7DB9FB', color2: '#1F5BF3' },
        { id: 'temp', name: '温度', percent: 0, value: 0, color1: '#A87EF3', color2: '#A835EB' },
        { id: 'hum', name: '湿度', percent: 0, value: 0, color1: '#4444FF', color2: '#4CCFF5' }
      ]
    }
  },
  created () {

  },
  mounted () {
    this.init()
    // this.drawSmallEcharts()
  },
  methods: {
    init () {
      getMonthAlarm().then(res => {
        const { data } = res
        if (Object.keys(data).length > 0) {
          this.chartArr.map(el => {
            switch (el.name) {
              case 'CO':
                el.value = Number(data.co)
                this.smallCharts[0].value = Number(data.co)
                this.smallCharts[0].percent = Number(data.coPercent)
                break;
              case 'CO2':
                el.value = Number(data.co2)
                this.smallCharts[1].value = Number(data.co2)
                this.smallCharts[1].percent = Number(data.co2Percent)
                break;
              case '甲醛':
                el.value = Number(data.formaldehyde)
                this.smallCharts[2].value = Number(data.formaldehyde)
                this.smallCharts[2].percent = Number(data.formaldehydePercent)
                break;
              case '温度':
                el.value = Number(data.temperature)
                this.smallCharts[3].value = Number(data.temperature)
                this.smallCharts[3].percent = Number(data.temperaturePercent)
                break;
              case '湿度':
                el.value = Number(data.humidity)
                this.smallCharts[4].value = Number(data.humidity)
                this.smallCharts[4].percent = Number(data.humidityPercent)
                break;
            }
          })

        }
        this.$nextTick(() => {
          this.drawEcharts()
          this.smallCharts.map(el => {
            const { id, name, percent, color1, color2 } = el
            this.drawSmallEcharts(id, name, percent, color1, color2)
          })
        })
      })


    },
    drawEcharts () {
      this.chart = this.$echarts.init(document.getElementById('alarm-charts'))
      const option = {
        series: [
          {
            name: "",
            type: 'gauge',
            // splitNumber: 60, //刻度数量
            min: 0,
            max: 100,
            radius: '80%', //图表尺寸
            center: ['50%', '50%'],
            startAngle: 90,
            endAngle: -269.9999,
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                shadowBlur: 0,
                color: [
                  [1, '#0dc2fe']
                ]
              }
            },
            axisTick: {
              splitNumber: 6,
              lineStyle: {
                color: '#0481C3',
                shadowBlur: 20,
                shadowColor: 'rgba(3, 50, 92, 1)'
              }
            },
            splitLine: {
              show: true,
              length: 10,
              distance: 10,
              lineStyle: {
                color: '#06AEFC',
                width: 2
              }
            },
            axisLabel: {
              show: false
            },
            pointer: { //仪表盘指针
              show: 0,
            },
            detail: {
              show: 0,
            },
          },
          {
            type: 'pie',
            radius: ['41%', '60%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                formatter: function (params) {
                  return '{count|' + `${params.percent}%` + '}\n{name|' + params.name + '}'
                },
                rich: {
                  count: {
                    color: '#FFFFFF',
                    fontSize: '24',
                    lineHeight: '50'
                  },
                  name: {
                    color: '#FFFFFF',
                    fontSize: '14',
                    lineHeight: '20'
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: this.chartArr
          }
        ]
      }
      option && this.chart.setOption(option)
      window.addEventListener("resize", () => {
        this.chart.resize()
      })
      this.timerFun(this.chart)
      this.chart.on('mouseover', params => {
        clearInterval(this.timeInterval)
        if (this.dataIndex !== params.dataIndex) {
          this.chart.dispatchAction({
            type: 'downplay',
            dataIndex: this.dataIndex
          })
        }
        this.dataIndex = params.dataIndex
        // this.chart.dispatchAction({
        //   type: 'highlight',
        //   dataIndex: this.dataIndex
        // })
      })
      this.chart.on('mouseout', () => {
        // this.timer= setInterval(looper(), 3000);
        this.timerFun(this.chart)
      })
    },
    timerFun (chartdata) {
      this.timeInterval = setInterval(() => {
        this.chartArr.length === this.dataIndex + 1 ? this.dataIndex = 0 : this.dataIndex++
        chartdata.dispatchAction({
          type: 'downplay',
          dataIndex: this.dataIndex === 0 ? this.chartArr.length - 1 : this.dataIndex - 1
        })

        chartdata.dispatchAction({
          type: 'highlight',

          dataIndex: this.dataIndex
        })
      }, 2000)
    },
    drawSmallEcharts (id, name, value, color1, color2) {
      const chart = this.$echarts.init(document.getElementById(`${id}-charts`))
      let angle = 0; //角度，用来做简单的动画效果的
      // let value = 65; //图上角度数据
      // let name = 'CO'
      const option = {
        title: {
          text: "{a|" + name + "}",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 16,
                color: "#FFFFFF",
                fontWeight: "bold",
              }
            },
          },
        },
        series: [
          {
            name: "",
            type: "custom",
            coordinateSystem: "none",
            renderItem: function (params, api) {
              return {
                type: "arc",
                shape: {
                  cx: api.getWidth() / 2,
                  cy: api.getHeight() / 2,
                  r: (Math.min(api.getWidth(), api.getHeight()) / 1.5) * 0.65,
                  startAngle: ((0 + -angle) * Math.PI) / 180,
                  endAngle: ((360 + -angle) * Math.PI) / 180,
                },
                style: {
                  stroke: color1,
                  fill: "transparent",
                  lineWidth: 2.5,
                },
                silent: true,
              };
            },
            data: [0],
          },
          {
            name: "",
            type: "pie",
            radius: ["75%", "60%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  // normal: {
                  color: {
                    // 完成的圆环的颜色
                    colorStops: [
                      {
                        offset: 0,
                        color: color1, // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: color2, // 100% 处的颜色
                      },
                    ],
                    // },
                  },
                },
              },
              {
                value: 100 - value,
                name: "",
                label: {
                  // normal: {
                  show: false,
                  // },
                },
                itemStyle: {
                  // normal: {
                  color: "#173164",
                  // },
                }
              }
            ]
          },
          {
            name: "",
            type: "gauge",
            radius: "83%",
            center: ["50%", "50%"],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: "110%",
              lineStyle: {
                width: 2,
                color: "rgba(0,0,0,0.6)",
              },
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
        ]
      }
      option && chart.setOption(option)
    }
  }
};
</script>

<style scoped lang="scss">
.air-left-top {
  width: 100%;
  height: 388px;
  background: url("../../../../assets/air/left_top.png") no-repeat;
  background-size: 100% 100%;
  .details {
    width: 100%;
    height: 260px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    #alarm-charts {
      width: 48%;
      height: 100%;
    }
    ul {
      display: flex;
      width: 52%;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: 50%;
        > p {
          width: 60px;
          height: 60px;
          // background: red;
          margin-right: 8px;
        }
        > div {
          h4 {
            font-size: 22px;
            font-weight: bold;
            line-height: 1;
            color: #f3ffff;
            margin-bottom: 8px;
          }
          p {
            font-size: 16px;
            color: #37fef7;
            line-height: 1;
          }
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
